import React from "react";
import Page from "../Components/Page";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";
import {universeValues} from "../universeValues"
import {
    EditFormBooleanDropdownInputField,
    EditFormDropdownInputField,
    EditFormNumberInputField,
    EditFormTextInputField
} from "../Components/ReactTable/EditRecordForm";
import {LoadingWrapper} from "../Components/LoadingWrapper";

function CellsService() {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/cells/`;
    const { loading, data, onRowEditConfirmed, onRowDeleteConfirmed } = useServiceBase({ endpointUrl });
    const projectedUniverse = [
        { value: "CS", label: "CS" },
        { value: "INFR", label: "INFR" },
        { value: "NCGA", label: "NCGA" },
        { value: "NWG", label: "NWG" },
        { value: "PHM", label: "PHM" },
        { value: "SG", label: "SG" },
        { value: "SGKA", label: "SGKA" },
        { value: "SN", label: "SN" },
        { value: "SN700", label: "SN700" },
        { value: "SN701", label: "SN701" },
        { value: "SNFL", label: "SNFL" },
    ]

    return (
        <Page name={"Cells"}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={[
                        {header: "Cell Id", filterable: true, accessorKey: "index"},
                        {header: "Cell Tag", filterable: true, accessorKey: "cell_tag"},
                        {header: "Description", filterable: true, accessorKey: "description"},
                        {header: "Is Base Cell", filterable: true, accessorKey: "is_base_cell"},
                        {header: "Universe", filterable: true, accessorKey: "universe"},
                        {header: "Projected Universe", filterable: true, accessorKey: "projected_universe"},
                        {header: "Universe Acv", filterable: true, accessorKey: "universe_acv"},
                        {header: "Store Count", filterable: true, accessorKey: "store_count"}
                    ]}
                    editTable={[
                        INDEX_FIELD,
                        {id: 'cell_tag', Component: args => <EditFormNumberInputField {...args} />}, //auto-generate if empty
                        {id: 'description', Component: args => <EditFormTextInputField {...args} isRequired/>},
                        {id: 'is_base_cell', defaultValue: false, Component: args => <EditFormBooleanDropdownInputField {...args} />},
                        {id: 'universe', Component: args => <EditFormDropdownInputField {...args} isRequired options={universeValues}/>},
                        {id: 'projected_universe', Component: args => <EditFormDropdownInputField {...args} isRequired options={projectedUniverse}/>},
                        {id: 'universe_acv', Component: args => <EditFormNumberInputField {...args} />},
                        {id: 'store_count', Component: args => <EditFormNumberInputField {...args} />}
                    ]}
                    initialState={{pagination: {pageSize: 25}}}
                    onRowEditConfirmed={onRowEditConfirmed}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>
        </Page>
    );
}

export default CellsService;
