import React from 'react';
import {LoadingWrapper} from "../Components/LoadingWrapper";
import EditableReactTableV2 from "../Components/ReactTable/EditableReactTableV2";
import Page from "../Components/Page";
import {useServiceBase} from "../Components/ReactTable/useServiceBase";
import {EditFormTextInputField} from "../Components/ReactTable/EditRecordForm";
import {INDEX_FIELD} from "../Components/ReactTable/common_custom_columns";

const MarketStoresService = () => {
    const endpointUrl = `${process.env.REACT_APP_EDP_API_BASE_URL}/marketStores`;
    const {loading, data, onRowEditConfirmed, onRowDeleteConfirmed} = useServiceBase({endpointUrl});
    return (
        <Page name={"Market to Stores"}>
            <LoadingWrapper loading={loading}>
                <EditableReactTableV2
                    filterable
                    data={data.data}
                    columns={[
                        {header: "Market Id", filterable: true, id: "market_id", accessorKey: "market_id"},
                        {header: "Store Id", filterable: true, id: "store_id", accessorKey: "store_id"}]
                    }
                    editTable={[
                        INDEX_FIELD,
                        {id: 'market_id', Component: args => <EditFormTextInputField {...args} isRequired/>},
                        {id: 'store_id', Component: args => <EditFormTextInputField {...args} isRequired/>},
                    ]}
                    initialState={{pagination: {pageSize: 25}}}
                    onRowEditConfirmed={onRowEditConfirmed}
                    onRowDeleteConfirmed={onRowDeleteConfirmed}
                />
            </LoadingWrapper>
        </Page>
    );
};

export default MarketStoresService;